import React from "react"
import PropTypes from "prop-types"
import "../assets/styles/layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <main>
        <div id="site-wrapper">
          <div id="body">{children}</div>
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
