import React from "react"
import "../assets/styles/layout.css"
import { useState, useEffect } from "react"

const Header = ({ siteTitle }) => {
  const [typing, setTyping] = useState("")

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useEffect(() => {
    const typeIt = async text => {
      for (let i = 0; i < text.length; i++) {
        const letter = new Promise((resolve, reject) => {
          setTimeout(
            () => resolve(text.slice(0, i + 1)),
            randomIntFromInterval(100, 270)
          )
        })
        setTyping(await letter)
      }
    }
    typeIt("software engineer")
  }, [])

  return (
    <header id="title">
      <h1>spencer goodman</h1>
      <h2>
        {typing}
        <span id="input">_</span>
      </h2>
    </header>
  )
}

export default Header
